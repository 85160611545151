import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import './header.css'

interface UserMenuProps {
    user?: DipUser;
}

interface DipUser {
    name?: string,
    email?: string
}

export const LoginState: React.FC<UserMenuProps> = ({ user }) => {
    const profileEditUrl = process.env.REACT_APP_APP_SWITCHER_DIP_PROFILE_EDIT_URL;
    const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
    const { loginWithRedirect } = useAuth0();
    const { logout } = useAuth0();
    const userDropdownRef = useRef<HTMLLIElement>(null);
    console.log("user: ", user)
    const handleMenuExpand = useCallback(() => {
        setIsMenuActive((current) => !current);
    }, []);

    const logoutClick = () => {
        // Add your logout functionality here
        logout({ logoutParams: { returnTo: window.location.origin } })
    }

    const loginClick = () => {
        // Add your login functionality here
        loginWithRedirect()
    }

    const getInitials = (user: any): string => {
        if (user == null) return "";
        if (user.name == undefined) {
            user.name = `${user["https://danfoss.com/first_name"] || ''} ${user["https://danfoss.com/last_name"] || ''}`
        }
        return (
            user.name?.split(' ').map((namePart: any, _index: number) => {
                return namePart.charAt(0);
            }).join(' ') ?? ''
        );
    };
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (userDropdownRef.current && !userDropdownRef.current.contains(event.target as Node)) {
                setIsMenuActive(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const isLoggedIn = user !== undefined;

    return (
        <li slot="link" className={!isMenuActive ? 'df-nav-item' : 'df-nav-item open'} ref={userDropdownRef}>
            {isLoggedIn ? (
                <>
                    <span className="df-icon df-context-menu-button" id="context-menu-button">
                        <button
                            className="df-btn-transparent df-text-regular df-link-tertiary"
                            onClick={handleMenuExpand}
                            aria-haspopup="true"
                            aria-controls="context-menu-dropdown"
                            aria-label="Account Options"
                            aria-expanded="false"
                            type="button"
                        >
                            <span className="df-icon df-addon-start df-color-tertiary">
                                <picture>
                                    <img src="/images/icons/header_User.svg" alt="User menu" />
                                </picture>
                            </span>
                            {getInitials(user || null)}
                        </button>
                    </span>
                    <ul
                        className="df-context-menu-overlay position-right-overide"
                        id="context-menu-dropdown"
                        aria-labelledby="context-menu-button"
                        role="menu"
                        onMouseLeave={handleMenuExpand}
                    >
                        <>
                            {profileEditUrl &&
                                <li className='mb-4'>
                                    <Link className="df-link-secondary df-text-bold flex-align-baseline"
                                        to={profileEditUrl}
                                        target="_blank">
                                        <span className="df-icon df-addon-start">
                                            <svg role="img" width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <title>Chevron link</title>
                                                <use href="/images/icons/chevron-small.svg#chevron-small"></use>
                                            </svg>
                                        </span>
                                        <span>
                                            Your profile
                                            <span className='display-block'>
                                                <div className='user-info'>{user.name}</div>
                                                <div className='user-info'>{user.email}</div>
                                            </span>
                                        </span>
                                    </Link>
                                </li>
                            }
                            <li>
                                <Link to="#" className="df-link-secondary df-text-bold flex-align-baseline" onClick={() => logoutClick()}>
                                    <span className="df-icon df-addon-start">
                                        <svg role="img" width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <title>Chevron link</title>
                                            <use href="/images/icons/chevron-small.svg#chevron-small"></use>
                                        </svg>
                                    </span>
                                    <span>
                                        Log out
                                    </span>
                                </Link>
                            </li>
                        </>
                    </ul>
                </>
            ) : (
                <Link to="#" className="sc-df-header df-link-tertiary inactive" onClick={() => loginClick()}>
                    <span className="df-icon df-addon-start df-color-secondary">
                        <picture>
                            <img src="/images/icons/header_User.svg" alt="Danfoss logo" />
                        </picture>
                    </span>
                    <span className="sc-df-header df-link-text">Log in</span>
                </Link>
            )}
        </li>
    );
};